<template>
  <div>
    <!-- start::password -->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Change Password</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example">
          <v-form ref="pform" v-model="valid_password" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="password"
                  label="Password"
                  outlined
                  type="password"
                  :rules="passwordRule"
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="confirm_password"
                  label="Confirm Password"
                  type="password"
                  :rules="confirmPasswordRule"
                  outlined
                  dense
                  required
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-container fluid class="card-footer pl-0 pt-3">
              <v-btn
                :disabled="!valid_password"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validatePasswordForm"
              >
                Update Password
              </v-btn>
            </v-container>
          </v-form>
        </div>
      </div>
    </div>
    <!-- end::password -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { GET_COUNTRIES, GET_STATES } from "@/core/services/store/user.module";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "profileEdit",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {      
      password: "",
      confirm_password: "",
      valid: true,
      valid_password: true,
      passwordRule: [
        v => !!v || "Password is required",
        v =>
          (v && v.length >= 6) ||
          "Password must be greater than or equal 6 characters"
      ],
      confirmPasswordRule: [
        v =>
          (v && v == this.password) ||
          "Password and Confirm Password is not same"
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  watch: {
    
  },
  mounted() {
    let context = this;

    this.$store.dispatch(GET_COUNTRIES);
    this.$store.dispatch(GET_STATES);
    if (context.getStates) {
      context.states = context.getStates;
      context.states.forEach(state => {
        context.state_list.push({ text: state.name, value: state.id });
      });
    } else {
      axios({
        method: "post",
        url: "get-states",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.states) {
            context.states = result.data.states;
            result.data.states.forEach(state => {
              context.state_list.push({ text: state.name, value: state.id });
            });
          } else {
            Swal.fire("Error", "States not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "States not found!", "error");
        }
      );
    }

    if (context.getCountries) {
      context.countries = context.getCountries;
      context.countries.forEach(country => {
        context.country_list.push(country.name);
      });
    } else {
      axios({
        method: "post",
        url: "get-countries",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.countries) {
            result.data.countries.forEach(country => {
              context.country_list.push({
                text: country.name,
                value: country.id
              });
            });
          } else {
            Swal.fire("Error", "Countries not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "Countries not found!", "error");
        }
      );
    }

    if (context.getOrganizatonTypes) {
      context.org_types = context.getOrganizatonTypes;
      context.org_types.forEach(org_type => {
        context.org_type_list.push(org_type.type);
      });
    } else {
      this.getOrganizatonTypesObj();
    }

    // if (context.getCompany) {
    //   context.employee.company = context.getCompany;
    //   context.employee.company_name = context.getCompany.name;
    // } else {
    //If company data not received from user.module store
    context.getCompanyObj();
    // }

    if (context.currentUserPersonalInfo) {
      context.employee.first_name = context.currentUserPersonalInfo.name;
      context.employee.last_name = context.currentUserPersonalInfo.surname;
      context.employee.email = context.currentUserPersonalInfo.email;
      context.employee.phone = context.currentUserPersonalInfo.phone;
    } 

    if (context.currentUser && context.currentUser.user) {
      context.employee.address = context.currentUser.user.address;
      context.employee.zipcode = context.currentUser.user.zipcode;
      context.employee.country_id = context.currentUser.user.country_id;
      context.employee.state_id = context.currentUser.user.state_id;
      context.employee.zipcode = context.currentUser.user.zipcode;
    } else {
      context.getCurrentUser();
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);

    const profile_form = KTUtil.getById("kt_profile_form");

    this.fv = formValidation(profile_form, {});

    //update first_time_login flag
    axios({
        method: "post",
        url: "update-user-info",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {      
          console.log('first_time_login updated')   
        }        
      );
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getCurrentUser() {
      let context = this;
      axios({
        method: "get",
        url: "get-current-user",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.user) {
            context.employee.address = result.data.user.address;
            context.employee.zipcode = result.data.user.zipcode;
            context.employee.country_id = result.data.user.country_id;
            context.employee.state_id = result.data.user.state_id;
            context.employee.zipcode = result.data.user.zipcode;
          } else {
            // context.getCurrentUser();
          }
        },
        function() {
          context.getCurrentUser();
        }
      );
    },
    getOrganizatonTypesObj() {
      let context = this;
      axios({
        method: "post",
        url: "get-organization-types",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.all_org_types = result.data.organization_types;
          if (!result.data.organization_types) {
            context.getOrganizatonTypesObj();
          }
        },
        function() {
          context.getOrganizatonTypesObj();
        }
      );
    },
    getCompanyObj() {
      let context = this;
      axios({
        method: "post",
        url: "get-company",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.company) {
            context.company_type = result.data.company.company_type;

            result.data.company.org_type = parseInt(result.data.company.org_type)
            context.employee.company = result.data.company;
            context.employee.company.industry =
              context.employee.company.industry != "null"
                ? context.employee.company.industry
                : "";
            context.employee.company.sic_naic =
              context.employee.company.sic_naic != "null"
                ? context.employee.company.sic_naic
                : "";
            context.employee.company.ein =
              context.employee.company.ein != "null"
                ? context.employee.company.ein
                : "";
            context.employee.company_name = result.data.company.name;
          } else {
            Swal.fire(result.data.error, "", "error");
          }
          context.all_org_types.forEach(org_type => {
            if (org_type.company_type == "Small Enterprise") {              
              context.small_org_type_list.push({ text: org_type.type, value: org_type.id });
            } else if (context.company_type == "Chapter Organization") {
              context.chapter_org_type_list.push({ text: org_type.type, value: org_type.id });
            }
          });
        },
        function() {
          context.getCompanyObj();
        }
      );
    },
    onFileChange(e) {
      if (e) {
        this.currentUserPersonalInfo.photo = URL.createObjectURL(e); // jQuery selector
      }
    },
    changeStates() {
      let context = this;
      context.state_list = [];
      context.states.forEach(state => {
        if (state.country_id == context.employee.country_id) {
          context.state_list.push({ text: state.name, value: state.id });
        }
      });
    },
    validateProfileForm() {
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.updateProfile();
      } else {
        this.snackbar = true;
      }
    },
    validatePasswordForm() {
      this.fv.on("core.pform.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.pform.validate()) {
        this.updatePassword();
      } else {
        this.snackbar = true;
      }
    },
    updatePassword() {
      // let context = this;
      this.$store.dispatch(UPDATE_PASSWORD, this.password);
      // .then(() => context.initPasswordForm());
    },
    updateProfile() {
      var context = this;
      var formData = new FormData();
      //User's Data
      formData.append("avatar", context.employee.avatar);
      formData.append("first_name", context.employee.first_name);
      formData.append("last_name", context.employee.last_name);
      formData.append("email", context.employee.email);
      formData.append("phone", context.employee.phone);
      formData.append("address", context.employee.address);
      formData.append("zipcode", context.employee.zipcode);
      formData.append("country_id", context.employee.country_id);
      formData.append("state_id", context.employee.state_id);

      //Company's Data
      formData.append("company_name", context.employee.company_name);
      formData.append("emp_count", context.employee.company.emp_count);
      formData.append("org_type", context.employee.company.org_type);
      formData.append("industry", context.employee.company.industry);
      formData.append("revenue", context.employee.company.revenue);
      formData.append("sic_naic", context.employee.company.sic_naic);
      formData.append("ein", context.employee.company.ein);

      axios({
        method: "post",
        url: "profile-update",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: formData,
        headers: {
          "Content-type": "application/x-www-form-urlencoded"
        }
      }).then(
        function(result) {
          Swal.fire("Success", result.data.message, "success");
        },
        function() {
          Swal.fire("Error", "Error in profile update", "error");
        }
      );
    },
    initPasswordForm() {
      let context = this;
      context.password = "";
      context.confirm = "";
    }
  }
};
</script>
